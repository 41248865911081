import React, { useEffect, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchAndActivate, getAll, getRemoteConfig } from "firebase/remote-config";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";

import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { getAllSearchParams } from "helpers/getAllSearchParams";
import { ampli } from "services/ampli";
import { app } from "services/firebase";
import { saveToStorage } from "services/storage";
import { getDayOfYear } from "helpers/dateTime";
import { isProductionHost } from "helpers/isProductionHost";
import { getIpInfo } from "http-client/ip-info";
import { AppRoutes } from "enums/routes.enum";
import { RESTRICTED_COUNTRIES } from "constants/restrictedCountries.const";
import { PROJECT_NAME } from "constants/env.const";
import UAParser from "ua-parser-js";
import { createUserAttribution, ICreateUserAttributionParams } from "http-client/attribution.client";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { updateWebSession } from "http-client/session.client";
import { isbot } from "isbot";

export const AmplitudeProvider = observer(({ children }: React.PropsWithChildren) => {
    const [isAmpliLoaded, setIsAmpliLoaded] = useState<boolean>(false);
    const [ampliResponse, setAmpliResponse] = useState<Record<string, any> | null>(null);
    const {
        amplitudeUserId,
        setAmplitudeUserId,
        setAmplitudeDeviceId,
        setAmpliIDLoading,
        flowOuter,
        setFlowOuter,
        abTestData: sessionAbTestData,
        setAbTestData: setSessionAbTestData,
        ipInfo,
        setIpInfo,
    } = useAppData();

    const { user, userProfile, fetchUserSession, checkUserSubscription, userWebSession } = useUserStore();
    const [abTestData, setAbTestData] = useState<Record<string, string | boolean> | null>(null);
    const navigate = useNavigate();

    const { pathname, hash } = useLocation();
    const [searchParams] = useSearchParams();

    const allQueryParams = getAllSearchParams(searchParams);
    const {
        usid: usidQueryParam,
        flowOuter: flowOuterQueryParam,
        gclid: gclidQueryParam,
        fbclid: fbclidQueryParam,
        utm_source: utmSourceQueryParam,
        utm_medium: utmMediumQueryParam,
        utm_campaign: utmCampaignQueryParam,
        utm_content: utmContentQueryParam,
        utm_term: utmTermQueryParam,
        utm_ad: utmAdQueryParam,
        utm_placement: utmPlacementQueryParam,
        is_test: isTestQueryParam,
        deviceId: deviceIdSearchParam,
    } = allQueryParams as Record<string, string>;

    const isTestUser = isTestQueryParam ? JSON.parse(isTestQueryParam) : userWebSession?.session?.isTestUser || sessionStorage.getItem("isTestUser") === "true";

    const usid = userProfile?.amplitudeSessionId || amplitudeUserId || usidQueryParam;
    const sendInitialAttributionEvent =
        (!localStorage.getItem("attributionEventSent") || localStorage.getItem("attributionEventSent") !== usid) && !userProfile?.amplitudeSessionId;

    const isAttributionSent =
        userWebSession?.session?.webAttributionSent ||
        !sendInitialAttributionEvent ||
        (userWebSession?.session?.webAttributionSent && !userProfile?.amplitudeSessionId);

    const saveAttributionSentState = () => {
        if (user) {
            updateWebSession({ ...userWebSession, webAttributionSent: true });
        }
        localStorage.setItem("attributionEventSent", usid);
    };

    const getAttributionParams = ({
        device_id,
        user_id,
    }: {
        device_id?: string;
        user_id?: string;
    } = {}) => {
        const deviceInfo = new UAParser().getResult();

        const commonUserData: Record<string, any> = {
            device_id: deviceIdSearchParam || device_id,
            user_id,
            country: ipInfo?.country,
            project_name: PROJECT_NAME,
            device_category: deviceInfo.device.type,
            browser: deviceInfo.browser.name,
            browser_version: deviceInfo.browser.version,
            operating_system: deviceInfo.os.name,
            operating_system_version: deviceInfo.os.version,
            device_model: deviceInfo.device.model,
            device_brand: deviceInfo.device.vendor,
            device_language: navigator.language,
            is_test: !isProductionHost() || isTestQueryParam || isTestUser,

            utm_source: utmSourceQueryParam,
            utm_medium: utmMediumQueryParam,
            utm_campaign: utmCampaignQueryParam,
            utm_content: utmContentQueryParam,
            utm_ad: utmAdQueryParam,
            utm_term: utmTermQueryParam,
            gclid: gclidQueryParam,
            fbclid: fbclidQueryParam,
            utm_placement: utmPlacementQueryParam,

            initial_utm_placement: utmPlacementQueryParam,
            initial_utm_ad: utmAdQueryParam,
        };

        const initialAmpliUserData = {
            initial_utm_source: utmSourceQueryParam,
            initial_utm_medium: utmMediumQueryParam,
            initial_utm_campaign: utmCampaignQueryParam,
            initial_utm_content: utmContentQueryParam,
            initial_utm_term: utmTermQueryParam,
            initial_gclid: gclidQueryParam,
            initial_fbclid: fbclidQueryParam,
            initial_utm_placement: utmPlacementQueryParam,
            initial_utm_ad: utmAdQueryParam,
            ...commonUserData,
        } as Record<string, string | number | boolean>;

        if (utmSourceQueryParam) {
            initialAmpliUserData.cohort_day = getDayOfYear();
        }

        return {
            initialAmpliUserData,
            commonUserData,
        };
    };

    useEffect(() => {
        if (!ampliResponse) {
            return;
        }

        if (!isAttributionSent) {
            const { initialAmpliUserData, commonUserData } = getAttributionParams({
                device_id: deviceIdSearchParam || ampliResponse.event.device_id,
                user_id: ampliResponse.event.user_id,
            });

            const getAttributes = (data: Record<string, any>) => JSON.parse(JSON.stringify(data));

            handleAmpliLoaded(() => ampli.webAttribution(getAttributes(initialAmpliUserData)));

            if (sendInitialAttributionEvent ?? !userWebSession?.session?.webAttributionSent) {
                createUserAttribution(getAttributes(commonUserData) as ICreateUserAttributionParams);
                saveAttributionSentState();
            }
        }
    }, [ampliResponse]);

    // utm_source=LT_WO_test&utm_campaign=test_campaign&utm_content=test_adset&utm_term=test_keyword

    useEffect(() => {
        if (isTestQueryParam) {
            sessionStorage.setItem("isTestUser", isTestQueryParam);
        }
    }, [isTestQueryParam]);

    useEffect(() => {
        /* For debugging purposes */
        (window as any).flowOuter = flowOuter;
    }, [flowOuter]);

    useEffect(() => {
        if (!ipInfo) {
            (async () => {
                const ip = await getIpInfo(flowOuter);
                setIpInfo(ip);
            })();
        } else if (RESTRICTED_COUNTRIES.includes(ipInfo?.country)) {
            navigate(AppRoutes.RESTRICTED_ACCESS);
        }
    }, [ipInfo]);

    useLayoutEffect(() => {
        (async () => {
            try {
                const remoteConfig = getRemoteConfig(app);
                remoteConfig.settings.minimumFetchIntervalMillis = 3600000; /* 1 hour */

                await fetchAndActivate(remoteConfig);
                const allAbTestFlags = getAll(remoteConfig);
                const abTestPaymentScreenOptionsNonLegal = allAbTestFlags["payment_screen_options_non_legal"]?.asString();
                const abTestPaymentScreenOptionsLegal = allAbTestFlags["payment_screen_options_legal"]?.asString();
                const abTestPaywall = allAbTestFlags["ab_paywall"]?.asString();
                const abTestAuth = allAbTestFlags["ab_auth"]?.asString();
                const abTestLanding = allAbTestFlags["ab_landing"]?.asString();
                const abTestContact = allAbTestFlags["ab_contact"]?.asString();
                const abTestApplePay = allAbTestFlags["ab_applepay"]?.asString();

                setAbTestData((prevState) => {
                    return {
                        ...(prevState || {}),
                        abTestPaymentScreenOptionsNonLegal,
                        abTestPaymentScreenOptionsLegal,
                        abTestPaywall,
                        abTestAuth,
                        abTestLanding,
                        abTestContact,
                        abTestApplePay,
                    };
                });
            } catch (err) {
                console.error("Failed to fetch Firebase remote config");
            }
        })();
    }, []);

    useLayoutEffect(() => {
        (async () => {
            if (isAmpliLoaded) return;

            try {
                await ampli.load({ environment: isProductionHost() ? "production" : "development", client: { configuration: { defaultTracking: true } } })
                    .promise;
                setIsAmpliLoaded(true);
            } catch (err) {
                console.error("Failed to provide Amplitude initialization");
            }
        })();
    }, [isAmpliLoaded]);

    useLayoutEffect(() => {
        (async () => {
            if (!isAmpliLoaded || !abTestData) return;

            try {
                const usid = userProfile?.amplitudeSessionId || amplitudeUserId || usidQueryParam || uuidv4();

                const [userSessionResponse, userSubscriptionResponse] = await Promise.allSettled([fetchUserSession(), checkUserSubscription()]);
                const userSession = userSessionResponse.status === "fulfilled" ? userSessionResponse.value || {} : {};
                const userSubscription = userSubscriptionResponse.status === "fulfilled" ? userSubscriptionResponse.value : {};

                const ampliIdentifyData = {} as Record<string, any>;

                /* Setting up Amplitude User properties only if they have meaningful value */
                if (utmSourceQueryParam) {
                    ampliIdentifyData.cohort_day = getDayOfYear();
                }

                /* Try to get ab test data from previously stored session data if it exists */
                const sessionAbTestData = userSession?.record?.abTestData || {};

                const abTestPaymentScreenOptionsNonLegal =
                    sessionAbTestData.abTestPaymentScreenOptionsNonLegal || abTestData.abTestPaymentScreenOptionsNonLegal;
                const abTestPaymentScreenOptionsLegal = sessionAbTestData.abTestPaymentScreenOptionsLegal || abTestData.abTestPaymentScreenOptionsLegal;
                const abTestPaywall = sessionAbTestData.abTestPaywall || abTestData.abTestPaywall;
                const abTestLanding = sessionAbTestData.abTestLanding || abTestData.abTestLanding;
                const abTestAuth = sessionAbTestData.abTestAuth || abTestData.abTestAuth;
                const abTestContact = sessionAbTestData.abTestContact || abTestData.abTestContact;
                const abTestApplePay = sessionAbTestData.abTestApplePay || abTestData.abTestApplePay;

                ampliIdentifyData.payment_screen_options_non_legal = abTestPaymentScreenOptionsNonLegal;
                ampliIdentifyData.payment_screen_options_legal = abTestPaymentScreenOptionsLegal;
                ampliIdentifyData.ab_paywall = abTestPaywall;
                ampliIdentifyData.ab_landing = abTestLanding;
                ampliIdentifyData.ab_auth = abTestAuth;
                ampliIdentifyData.ab_contact = abTestContact;
                ampliIdentifyData.ab_applepay = abTestApplePay;
                ampliIdentifyData.is_bot = isbot(navigator.userAgent);

                const isPremium = userSubscription?.isPremium;
                const userEmail = user?.email;

                if (typeof isPremium == "boolean") {
                    ampliIdentifyData.subscription_active = isPremium;
                    const productId = userSubscription?.info?.productId;
                    if (productId) {
                        ampliIdentifyData.product_id = productId;
                    }
                }

                if (userEmail) {
                    ampliIdentifyData.user_email = userEmail;
                }

                if (deviceIdSearchParam) {
                    ampliIdentifyData.deviceId = deviceIdSearchParam;
                }

                const { initialAmpliUserData, commonUserData } = getAttributionParams({ user_id: usid });
                const userProps = !isAttributionSent ? initialAmpliUserData : commonUserData;

                const ampliIdentifyResponse = await ampli.identify(usid, JSON.parse(JSON.stringify({ ...ampliIdentifyData, ...userProps }))).promise;
                setAmpliResponse(ampliIdentifyResponse as Record<string, any>);
                setAmplitudeUserId(usid);
                setAmplitudeDeviceId(deviceIdSearchParam || (ampliIdentifyResponse as Record<string, any>).event.device_id);
            } catch (err) {
                console.error("Failed to provide Amplitude identification");
            }
        })();
    }, [isAmpliLoaded, userProfile?.amplitudeSessionId, abTestData]);

    useLayoutEffect(() => {
        /*
         * Saving into store and removing 'flowOuter' search
         * parameter from browser's URL bar on initial visit
         */
        if (!flowOuter && flowOuterQueryParam === "true") {
            setFlowOuter(true);
        }

        if (gclidQueryParam) {
            saveToStorage("gclid", gclidQueryParam);
        }

        /*
         *  Here we are visually updating 'usid' search param (usidQueryParam) in
         *  browsers URL bar when page navigation happens or if amplitudeUserId has changed.
         *  With exception of this AmplitudeProvider module, 'usid' search param should not be used
         *  as "source of truth" elsewhere inside application logic, use amplitudeUserId instead.
         */
        if (isAmpliLoaded && amplitudeUserId && amplitudeUserId !== usidQueryParam && abTestData) {
            const newQueryParams = { ...allQueryParams };

            if (flowOuterQueryParam) {
                delete (newQueryParams as any).flowOuter;
            }

            newQueryParams["usid"] = amplitudeUserId;

            navigate(
                {
                    pathname,
                    hash,
                    search: createSearchParams(newQueryParams).toString(),
                },
                { replace: true, preventScrollReset: true }
            );
        }

        /* Send amplitude page navigation event on last update */
        if (isAmpliLoaded && amplitudeUserId && amplitudeUserId === usidQueryParam && abTestData) {
            /* Saving AB test data into store */
            if (!sessionAbTestData && abTestData) {
                setSessionAbTestData(abTestData);
            }

            setAmpliIDLoading(false);
        }
    }, [isAmpliLoaded, pathname, amplitudeUserId, usidQueryParam, flowOuterQueryParam, gclidQueryParam, abTestData]);

    if (!isAmpliLoaded) {
        return null;
    }

    return <>{children}</>;
});
