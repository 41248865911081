import { Stack, SxProps } from "@mui/material";
import { SectionGradientDarkener } from "./SectionGradientDarkener";
import { WidthContainer } from "./WidthContainer";
import { FC, ReactNode } from "react";

interface Props {
    children: ReactNode;
    sx?: SxProps;
}

export const BackgroundImageSectionWrap: FC<Props> = ({ children, sx }) => (
    <Stack
        sx={{
            width: "100%",
            position: "relative",
            minHeight: "100vh",
            overflow: "hidden",
            paddingBottom: "5vh",
            background: {
                xs: "#2D3137 url(/images/mobile-bg-highlights.png) no-repeat center/cover",
                lmd: "#2D3137 url(/images/search-page-bg.png) no-repeat center/cover",
            },
            backgroundSize: "110% auto",
            ...sx,
        }}
    >
        <SectionGradientDarkener />
        <WidthContainer style={{ position: "relative" }}>{children}</WidthContainer>
    </Stack>
);
